// Load Styles
import '../scss/main.scss';

// Import only the Bootstrap components we need
import {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
} from 'bootstrap';

// vimeo performance embed
import '@slightlyoff/lite-vimeo';

// images lazyload and animation
import 'lazysizes';
import AOS from 'aos';

window.lazySizesConfig = {
  addClasses: true, // good to add loading styles
};

/**
 * Accessibility criteria: custom settings considered
 */
if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
  AOS.init({
    disable: true
  });
} else {
  AOS.init({
    throttleDelay: 199, // higher for smoother animation start
    duration: 1000,
    once: true,
  });

  // Call animation function after lazyload
  document.querySelectorAll('img').forEach(img => {
    img.addEventListener('load', AOS.refresh);
  });
  document.querySelectorAll('.figure').forEach(figure => {
    figure.addEventListener('load', AOS.refresh);
  });
}

/**
 * Event Handlers
 */
window.addEventListener('resize', function () {
  positionHighlightImage();
  positionBlockquoteImage();
});

window.addEventListener('scroll', function() {
  handleNavbarScroll();
});

document.addEventListener('DOMContentLoaded', function () {
  markActiveNavLink();
  toggleBackgroundVideo();
  handleNavbarScroll();
  if (document.querySelector('.panel')) {
    setupCategoriesAnimation();
  }
  positionHighlightImage();
  positionBlockquoteImage();
});

/**
 * Background Video Stop/Start
 */
function toggleBackgroundVideo() {
  const video = document.getElementById('bgVideo');
  const videoControlBtn = document.getElementById('videoControlBtn');

  if (video && videoControlBtn) {
    const svgElement = videoControlBtn.querySelector('svg');
    const textElement = videoControlBtn.querySelector('.visually-hidden');

    videoControlBtn.addEventListener('click', function () {
      if (video.paused) {
        video.play();
        svgElement.innerHTML = `<circle cx="18" cy="18" r="18"/><g stroke-linecap="round" stroke-width="4"><path d="m14 24v-12"/><path d="m22 24v-12"/></g>`;
        textElement.textContent = 'Pause';
      } else {
        video.pause();
        svgElement.innerHTML = `<circle cx="18" cy="18" r="18"/><path d="m26.5 17.134c.6667.3849.6667 1.3471 0 1.732l-12 6.9282c-.6667.3849-1.5-.0962-1.5-.866v-13.8564c0-.7698.8333-1.25093 1.5-.866z"/>`;
        textElement.textContent = 'Start';
      }
    });
  }
}

/**
 * Navbar White Background onScroll
 */
function handleNavbarScroll() {
  const navbar = document.querySelector('.navbar');
  const offcanvasNavbar = document.querySelector('.offcanvas .navbar');
  if (window.scrollY > 0) {
    navbar.classList.add('navbar-scrolled');
    offcanvasNavbar.classList.add('navbar-scrolled');
    navbar.removeAttribute('data-bs-theme');
  } else {
    navbar.classList.remove('navbar-scrolled');
    offcanvasNavbar.classList.remove('navbar-scrolled');
    if (document.querySelector('header').classList.contains('no-header-image')) {
      navbar.setAttribute('data-bs-theme', 'light');
    } else {
      navbar.setAttribute('data-bs-theme', 'dark');
    }
  }
}

/**
 * Animation for Categories Panels
 */
function setupCategoriesAnimation() {
  document.querySelectorAll('.panel').forEach(panel => {
    panel.addEventListener('mouseenter', () => {
      removeActiveClasses(); // Ensures only one panel is active at a time
      panel.classList.add('active');
    });

    // Extend interaction to focus events for keyboard accessibility
    const readMoreBtn = panel.querySelector('.btn-read-more');
    if (readMoreBtn) {
      readMoreBtn.addEventListener('focus', () => {
        removeActiveClasses(); // Consistency in active panel behavior
        panel.classList.add('active');
      });
    }
  });

  // Resets to the default active panel when leaving the container
  document.querySelector('.categories-container').addEventListener('mouseleave', restoreFirstPanelActive);

  function restoreFirstPanelActive() {
    removeActiveClasses();
    document.querySelector('.panel').classList.add('active'); // First panel as the default active
  }

  // Helper function to ensure only one active panel
  function removeActiveClasses() {
    document.querySelectorAll('.panel').forEach(panel => {
      panel.classList.remove('active');
    });
  }
}

/**
 * Calculate offset to position highlight images out of container on the viewport border
 * Image on viewport border until max 1728px viewport width. Then positioning stops.
 */
function positionHighlightImage() {
  const containerElement = document.querySelector('.highlight');
  if (containerElement) {
    const imgElements = document.querySelectorAll('.highlight-image');
    const contentWrappers = document.querySelectorAll('.highlight-text .content-wrapper');
    const viewportWidth = window.innerWidth;
    const innerWidth = containerElement.clientWidth;
    const halfContainer = innerWidth / 2;

    const style = window.getComputedStyle(containerElement);
    const paddingX = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const width = innerWidth - paddingX;

    let paddingXValue = innerWidth - width; // until < 768px this is 0
    let marginValue = ((viewportWidth - innerWidth) / 2) + (paddingXValue / 2);

    let translateNegativeExtra = (marginValue > 96) ? marginValue - 96 : 0;
    let translateContentValue = (marginValue > 96) ? marginValue - 96 : 0;

    // Limit gap between image and text to 96px maximum
    marginValue = (marginValue > 96) ? 96 : marginValue;

    imgElements.forEach((imgElement) => {
      let translateValue;
      let highlightTextElement;
      // Images can be left oder right to the text
      if (imgElement.classList.contains('order-md-2')) {
        translateValue = marginValue;
      } else {
        translateValue = -(marginValue + translateNegativeExtra);
        translateValue = (translateValue < -204) ? -204 : translateValue;
        highlightTextElement = imgElement.nextElementSibling;

      }

      // Apply the calculated transform or none if the viewport is too narrow
      if(viewportWidth >= 768) {
        imgElement.style.transform = `translateX(${translateValue}px)`;
        if(highlightTextElement) {
          translateContentValue = (translateContentValue > 108) ? 108 : translateContentValue;
          highlightTextElement.style.transform = `translateX(-${translateContentValue}px)`;
        }
      } else {
        imgElement.style.transform = `none`;
      }
    });

    contentWrappers.forEach((contentElement) => {
      let newWidth;

      if(viewportWidth >= 768) {
        newWidth = halfContainer - marginValue;
      }

      contentElement.style.width = `${newWidth}px`;
    });
  }
}

/**
 * Calculate offset to position blockquote image out of container on the viewport border
 * Image on viewport border until max 1728px viewport width. Then positioning stops.
 * Content Wrapper raises width until until max 1728px viewport width. Then resizing stops.
 */
function positionBlockquoteImage() {
  const containerElement = document.querySelector('.blockquote-with-image');
  if (containerElement) {
    const imgElements = document.querySelectorAll('.blockquote-image');
    const contentWrappers = document.querySelectorAll('.blockquote-text .content-wrapper');
    const viewportWidth = window.innerWidth;
    const innerWidth = containerElement.clientWidth;
    let col5Width = 0;
    if(viewportWidth >= 992) {
      col5Width = document.querySelector('.text-with-headline .col-lg-5').offsetWidth;
    } else if(viewportWidth >= 768) {
      col5Width = innerWidth / 2;
    }

    const style = window.getComputedStyle(containerElement);
    const paddingX = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const width = innerWidth - paddingX;
    let paddingXValue = innerWidth - width; // until < 768px this is 0
    let marginValue = ((viewportWidth - innerWidth) / 2) + (paddingXValue / 2); // Berechnung des Margins
    // Limit gap between image and text to 96px maximum
    marginValue = (marginValue > 96) ? 96 : marginValue;
    const customWidth = col5Width + marginValue; // Gesamtbreite = .col-5 Breite + Margin

    imgElements.forEach((imgElement) => {
      // Apply the calculated transform or none if the viewport is too narrow
      if(viewportWidth >= 768) {
        imgElement.style.width = `${customWidth}px`;
        imgElement.style.transform = `translateX(${marginValue}px)`;
      } else {
        imgElement.style.width = null;
        imgElement.style.transform = `none`;
      }
    });

    contentWrappers.forEach((contentElement) => {
      let newWidth;
      if(viewportWidth >= 768) {
        newWidth = viewportWidth - customWidth - ((viewportWidth - innerWidth) / 2) - marginValue + (paddingX / 2);
        newWidth = (newWidth > 782) ? 782 : newWidth;
      }

      contentElement.style.width = `${newWidth}px`;
    });
  }
}

/**
 * Marks the navigation link that matches the current URL path as active.
 */
function markActiveNavLink() {
  const navLinks = document.querySelectorAll('.navbar-nav a');
  const currentPath = window.location.pathname;

  navLinks.forEach(link => {
    if (currentPath.startsWith(link.getAttribute('href'))) {
      link.setAttribute('aria-current', 'page');
      link.classList.add('active');
    } else {
      link.removeAttribute('aria-current');
      link.classList.remove('active');
    }
  });
}